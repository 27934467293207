import React, { useEffect, useState } from 'react'
import Header from '../global/Header'
import StyleManagerIcon from '../../assets/images/stylemanager.png'
import { ReactComponent as FigmaIcon } from '../../assets/svgs/figma.svg'
import ComponentExporterIcon from '../../assets/images/componentexporter.png'
import { CheckIcon, HeartIcon, ArrowDownIcon } from '@heroicons/react/solid'
import { fetchPluginMetaData, checkHttpStatus } from '../../api/plugin'


const STYLE_MANAGER_PLUGIN_ID = '948597523260583481'
const COMPONENT_EXPORT_PLUGIN_ID = '1055671590531785988'

export default function Home() {
  const [styleManagerMetaData, setStyleManagerMetaData] = useState(null)
  const [componentExporterMetaData, setComponentExporterMetaData] = useState(null)

  useEffect(() => {
    fetchPluginMetaData(STYLE_MANAGER_PLUGIN_ID)
      .then(checkHttpStatus)
      .then(data => {
        if (data.status === 200) {
          setStyleManagerMetaData(data)
        }
      })
      .catch(err => {
        if ([404, 401].includes(err?.status)) {
          
        }
      })
  }, [])

  useEffect(() => {
    fetchPluginMetaData(COMPONENT_EXPORT_PLUGIN_ID)
      .then(checkHttpStatus)
      .then(data => {
        if (data.status === 200) {
          setComponentExporterMetaData(data)
        }
      })
      .catch(err => {
        if ([404, 401].includes(err?.status)) {
          
        }
      })
  }, [])

  return (
    <div className="min-h-screen max-w-5xl bg-white mx-auto">
      <Header />

      <main>
        <div className="relative pt-10 pb-20 px-4 px-4 sm:px-6 lg:px-16 lg:pt-10 lg:pb-10">
          <div className="relative">
            <div className="text-center mt-4 mb-16">
              <h2 className="text-5xl tracking-tight font-bold text-gray-900 leading-none">One up your productivity</h2>
              <p className="mt-3 max-w-2xl mx-auto text-2xl text-gray-500 leading-tight">
                Figma plugins for easier design asset management
              </p>
            </div>
            <div className="flex max-w-md mx-auto grid gap-5 lg:grid-cols-2 lg:max-w-4xl">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={StyleManagerIcon} alt="Style Manager" />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-xl text-transparent font-bold bg-clip-text bg-gradient-to-r from-green-500 via-blue-700 to-purple-700">
                      <a
                        href={`https://www.figma.com/community/plugin/${STYLE_MANAGER_PLUGIN_ID}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Style Manager
                      </a>
                    </p>
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900 my-3">
                        Manage your Figma style libraries like a pro!
                      </p>

                      <ul className="list-inside max-w-md mx-auto text-base text-gray-800">
                        <li className="flex items-center mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>Export any style collection</span>
                        </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>Import a collection into any document</span>
                        </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                            Share your collections with your team, or anyone
                          </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>Access your style data over web in json format to power your build processes</span>
                        </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>CI/CD ready API</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    {styleManagerMetaData &&
                      <div className="flex justify-center space-x-4 mt-6 text-lg font-semibold text-gray-800 mb-5">
                        <a
                          href={`https://www.figma.com/community/plugin/${STYLE_MANAGER_PLUGIN_ID}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center border-2 border-gray-300 hover:border-gray-400 rounded-full py-3 px-4"
                        >
                          <HeartIcon className="w-6 h-6 mr-2 text-red-600"/> {styleManagerMetaData.meta.plugin.like_count}
                        </a>
                        <a
                          href={`https://www.figma.com/community/plugin/${STYLE_MANAGER_PLUGIN_ID}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center border-2 border-gray-300 hover:border-gray-400 rounded-full py-3 px-4"
                        >
                          <ArrowDownIcon className="w-6 h-6 mr-2 text-green-600"/> {styleManagerMetaData.meta.plugin.install_count}
                        </a>  
                      </div>
                    }
                    <div>
                    <a
                      href={`https://www.figma.com/community/plugin/${STYLE_MANAGER_PLUGIN_ID}/`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      <div className="flex items-center space-x-1">
                        <FigmaIcon className="w-4" />
                        <span className="text-lg">Install</span>
                      </div>
                    </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                  <img className="h-48 w-full object-cover" src={ComponentExporterIcon} alt="Component Exporter" />
                </div>
                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div className="flex-1">
                    <p className="text-xl text-transparent font-bold bg-clip-text bg-gradient-to-r from-pink-500 via-blue-700 to-purple-700">
                      <a
                        href={`https://www.figma.com/community/plugin/${COMPONENT_EXPORT_PLUGIN_ID}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Component Exporter
                      </a>
                    </p>
                    <div className="block mt-2">
                      <p className="text-xl font-semibold text-gray-900 my-3">
                        Own your Figma components with our bulk exporter!
                      </p>

                      <ul className="list-inside max-w-md mx-auto text-base text-gray-800">
                        <li className="flex items-center mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>Bulk export large component libraries</span>
                        </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>Export team published components</span>
                        </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                            Dedicated collection web pages for sharing with teams or anyone
                          </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>Export to json, downloadable archives, and pngs</span>
                        </li>
                        <li className="flex mb-2">
                          <CheckIcon className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"/>
                          <span>CI/CD ready API</span>
                        </li>
                      </ul>

                      <p className="text-base text-gray-600 my-3">
                        Check the <a href="/component-exporter/documentation" className="text-blue-500 hover:text-blue-700 hover:underline">docs</a> to see how you or your team can benefit.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    {componentExporterMetaData &&
                      <div className="flex justify-center space-x-4 mt-6 text-lg font-semibold text-gray-800 mb-5">
                        <a
                          href={`https://www.figma.com/community/plugin/${COMPONENT_EXPORT_PLUGIN_ID}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center border-2 border-gray-300 hover:border-gray-400 rounded-full py-3 px-4"
                        >
                          <HeartIcon className="w-6 h-6 mr-2 text-red-600"/> {componentExporterMetaData.meta.plugin.like_count}
                        </a>
                        <a
                          href={`https://www.figma.com/community/plugin/${COMPONENT_EXPORT_PLUGIN_ID}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center border-2 border-gray-300 hover:border-gray-400 rounded-full py-3 px-4"
                        >
                          <ArrowDownIcon className="w-6 h-6 mr-2 text-green-600"/> {componentExporterMetaData.meta.plugin.install_count}
                        </a>  
                      </div>
                    }

                    <div>
                      <a
                        href={`https://www.figma.com/community/plugin/${COMPONENT_EXPORT_PLUGIN_ID}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      >
                        <div className="flex items-center space-x-1">
                          <FigmaIcon className="w-4" />
                          <span className="text-lg">Install</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}