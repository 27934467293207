import React from 'react'
import Header from '../global/Header'

export default function Final() {
  return (
    <div className="min-h-screen max-w-5xl bg-white mx-auto">
      <Header />

      <div className="mt-28 mx-4">
        <div className="bg-white rounded w-full xs:w-full sm:w-1/2 ml-auto mr-auto">
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Success!
                </h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>If you already have an access key,  then you are good to go.</p>
                  <p>If you did not receive an access key yet,  then you should recieve it now.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}