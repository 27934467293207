import React from 'react'
import Header from '../global/Header'
import onboardingemail from '../../assets/images/onboarding-email.png'
import onboardingfigma from '../../assets/images/onboarding-figma.png'
import onboardingfilekeylink from '../../assets/images/onboarding-filekeylink.png'
import onboardingfilekey from '../../assets/images/onboarding-filekey.png'
import exportingcol from '../../assets/images/exporting-col.png'


export default function Final() {
  return (
    <div className="min-h-screen max-w-5xl bg-white mx-auto">
      <Header />

      <main>
        <div className="relative pt-10 pb-20 px-4 px-4 sm:px-6 lg:px-16 lg:pt-10 lg:pb-10">
          <div className="relative">
            <h1 className="text-2xl tracking-tight font-bold text-gray-900">Component Exporter docs</h1>
            <p className="mt-6 text-xl text-gray-700 leading-8">
              The Component Exporter plugin uses backend services to export large or small sets of components from Figma documents. 
              This means the plugin UI is non-blocking while an export is running, and can be closed.
            </p>

            <h2 className="mt-8 text-xl tracking-tight font-bold text-gray-900">Getting started</h2>
            <p className="mt-4 text-xl text-gray-700 leading-8">
              Install the plugin, and request your access key by submitting your email from the settings page. 
              Activate your access key with the provided link, once you receive it in the email. Finally put in your key into the Access Key field in the plugin settings page.
              <img src={onboardingemail} className="w-72" alt="Onboarding with email" />
            </p>

            <p className="mt-4 text-xl text-gray-700 leading-8">
              Authenticate Figma to grant Component Exported <span className="font-bold text-color-black">read-only</span> access to your documents. 
              This grant can be revoked at any time.
              <img src={onboardingfigma} className="w-72" alt="Onboarding with Figma" />
            </p>

            <h2 className="mt-8 text-xl tracking-tight font-bold text-gray-900">Running exports</h2>
            <p className="mt-4 text-xl text-gray-700 leading-8">
              Your are now ready to run your first export.
            </p>

            <ul className="mt-4 text-xl text-gray-700 leading-8 pl-8 list-disc list-outside">
              <li>Create a collection from the <span className="font-bold text-color-black">Library</span> page.</li>
              <li>Click Export from the <span className="font-bold text-color-black">Home</span> page.</li>
              <li>Select the collection your are exporting to.</li>
              <li>
                <p>Supply the file key you want to export from. Usually the file you currently have open. Right click on the file tab, click Copy Link, open the link in any browser.</p>
                <img src={onboardingfilekeylink} className="w-72" alt="File key" />
                <p>The file key can be extracted from the url, and is usually a series of characters like in the image below.</p>
                <img src={onboardingfilekey} className="w-72" alt="File key link" />
              </li>
              <li>Select the page you are exporting from in your Figma document.</li>
              <li>Select to export team published or non-published components, and click <span className="font-bold text-color-black">Run</span>.</li>
            </ul>

            <p className="mt-4 text-xl text-gray-700 leading-8">
              Depending on the amount of components you are exporting, the export process could take some time.  Feel free to close the plugin at this time and check it later.
            </p>

            <p className="mt-4 text-xl text-gray-700 leading-8">
              You can check the progress of the export job by visiting the exported component's dedicated web page. 
              Enable public link, and grab the url.
              <img src={exportingcol} className="w-72" alt="Starting exports" />
            </p>

            <p>This web page will provide exported components in various formats.  JSON, full archive download, individual copy-to-paste svg code, individual file downloads in .svg and .png format.</p>

            <h2 className="mt-8 text-xl tracking-tight font-bold text-gray-900">CI/CD</h2>
            <p className="mt-4 text-xl text-gray-700 leading-8">
              Component Exporter Figma plugin is powered fully by a RESTful API.  
              This means it can be tied into CI/CD pipelines and used in conjunction with GitLab, GitHub, Jenkins, Kubernetes, and more.
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}