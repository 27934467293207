const apiServerUrl = process.env.REACT_APP_API_SERVER

export const fetchPluginMetaData = (pluginId) =>
  fetch(`${apiServerUrl}/api/figma/plugin/?plugin_id=${pluginId}`)

export const checkHttpStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response.json()
  }

  throw response
}