import React from 'react'
import '../../assets/sass/header.scss'
import logo from '../../assets/images/logo.svg'


const Header = () => {
  return (
    <header className="global-header px-4 sm:px-6 lg:px-16">
      <div className="max-w-container mx-auto">
        <div className="flex justify-between flex-col sm:flex-row">
          <a
          href="/"
            className="flex pt-4 pb-2 items-center text-sm leading-5"
          >
            <img src={logo} className="mr-2 text-white" alt="StylesUp logo" />
            <h1 className="leading-9 text-md text-transparent font-bold text-purple-700 sm:text-1xl sm:leading-10">
              StylesUp
            </h1>
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
