import queryString from 'query-string';
import axios from 'axios';
import React, { Component } from 'react';
import Header from '../global/Header'


class AccountVerify extends Component {
  state = {
    isFetching: true,
    activationToken: null,
    error: false
  }

  constructor(props) {
    super(props)
    this.state.activationToken = this.props.activationToken
  }

  async componentDidMount() {
    if (this.state.activationToken) {
      const apiUrl = `${process.env.REACT_APP_API_SERVER}/api/accounts/activate/${this.state.activationToken}`;

      try {
        await axios({
          method: 'get',
          url: apiUrl
        })
        .then((response) => {
          this.setState({
            message: `Good to go!`,
            response_message: response.data.message,
            error: false,
            isFetching: false
          })
        }, (error) => {
          this.setState({
            message: `Something went wrong...`,
            response_message: error.response.data.message,
            error: true,
            isFetching: false
          })
        })
      } catch(e) {
        console.error(e);
      }
    }
  }

  render() {
    return (
      <>
        {this.state.isFetching ? <span className="font-medium">Activating...</span> : null}

        {
          !this.state.isFetching ?
            <>
              {
              this.state.error
                ?
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        {this.state.message}
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        {this.state.response_message}
                      </div>
                    </div>
                  </div>
                </div>
                : 
                <div className="rounded-md bg-green-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-green-800">
                        {this.state.message}
                      </h3>
                      <div className="mt-2 text-sm text-green-700">
                        <p>
                          {this.state.response_message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
          : null
        }
      </>
    )
  }
}

class Activate extends Component {
  state = {
    accessKey: null,
    activationToken: null
  }

  constructor(props) {
    super(props);
    let url = this.props.location.search;
    let params = queryString.parse(url);

    this.state.apiAccessKey = params.access_key
    this.state.activationToken = params.activation_token
  }

  render() {
    return (
      <div className="min-h-screen max-w-5xl bg-white mx-auto">
        <Header />

        <div className="mt-28 mx-4">
          <div className="bg-white rounded w-full xs:w-full sm:w-1/2 ml-auto mr-auto">
            {
              this.state.activationToken
                ? <AccountVerify {...this.props} activationToken={this.state.activationToken} />
                : ''
            }
          </div>
        </div>
      </div>
    )
  }
}

export default Activate
