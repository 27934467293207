import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Activate from './components/onboard/Activate'
import Claim from './components/onboard/Claim'
import Final from './components/FigmaAuth/final'
import Home from './components/home'
import ComponentExporterDocs from './components/ComponentExporterDocs'

// is this needed?
import './index.css'
import './tailwind.output.css'


function QueryScreen() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/activate" component={Activate} />
      <Route exact path="/claim" component={Claim} />
      <Route exact path="/figma/auth/final" component={Final} />
      <Route exact path="/component-exporter/documentation" component={ComponentExporterDocs} />
    </Switch>
  )
}

function App() {
  return (
    <React.StrictMode>
      <Router>
        <QueryScreen />
      </Router>
    </React.StrictMode>
  )
}

const rootElement = document.getElementById('root')

ReactDOM.render(
  <App />,
  rootElement
)

serviceWorker.unregister()
